import { Fade } from '@mui/material'
import { Root, Inner } from './page-loader.styled'
import { useConfig } from '@bluheadless/ui-logic/src/providers/config'
import Head from 'next/head'
import Image from '@bluheadless/ui/src/particles/image'

const PageLoaderUI = ({ show }) => {
	const { seoMeta } = useConfig()
	return (
		<>
			<Fade id="page-loader" appear={false} in={show}>
				<Root show={show}>
					<Inner sx={{ textAlign: 'center' }}>
						<Image
							src="/loader/loader_optimized.webp"
							alt={seoMeta?.title}
							width="100px"
							height="100px"
							unoptimized
							layout="fixed"
							priority
							loading="eager"
						/>
					</Inner>
				</Root>
			</Fade>

			<Head>
				<noscript key="noscript-page-loader">
					<style
						dangerouslySetInnerHTML={{
							__html: `#page-loader { display: none !important; }`,
						}}
					/>
				</noscript>
			</Head>
		</>
	)
}

export default PageLoaderUI
