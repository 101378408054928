const Accordion = {
	MuiAccordionDetails: {
		styleOverrides: {
			root: {
				color: 'var(--color-primary)',
				padding: '0 0 33px 0',
			},
		},
	},

	MuiAccordionSummary: {
		styleOverrides: {
			root: {
				padding: '29px 0 29px 0',
				margin: 0,
			},
		},
	},
}

export default Accordion
