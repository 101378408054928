import { fonts } from '../fonts'
import defaultTheme from '@bluheadless/ui/src/theme/default'

const CssBaseline = {
	MuiCssBaseline: {
		styleOverrides: {
			':root, *::before, *::after': {
				colorScheme: 'light dark',
				// spacing
				'--spacing': '8px',
				'--spacing-1': 'calc(var(--spacing) * 1)',
				'--spacing-2': 'calc(var(--spacing) * 2)',
				'--spacing-3': 'calc(var(--spacing) * 3)',
				'--spacing-4': 'calc(var(--spacing) * 4)',
				'--spacing-5': 'calc(var(--spacing) * 5)',
				'--spacing-6': 'calc(var(--spacing) * 6)',
				'--spacing-7': 'calc(var(--spacing) * 7)',
				'--spacing-8': 'calc(var(--spacing) * 8)',
				'--spacing-9': 'calc(var(--spacing) * 9)',
				'--spacing-10': 'calc(var(--spacing) * 10)',
				// letter-spacing
				'--letter-spacing': '0.07em',
				'--letter-spacing-headlinebig': '0.05em',
				'--letter-spacing-h1': '0.05em',
				'--letter-spacing-h2': '0.05em',
				'--letter-spacing-h3': '0.05em',
				'--letter-spacing-h4': '0.05em',
				'--letter-spacing-h5': '0.05em',
				'--letter-spacing-h6': '0.08em',
				'--letter-spacing-body': '0.06em',
				'--letter-spacing-body16': '0.12em',
				// font-size
				'--font-size-headlinebig': defaultTheme.typography.pxToRem(24),
				'--font-size-headlinemedium': defaultTheme.typography.pxToRem(32),
				'--font-size-headlinesmall': defaultTheme.typography.pxToRem(32),
				'--font-size-subheadline1': defaultTheme.typography.pxToRem(16),
				'--font-size-subheadline2': defaultTheme.typography.pxToRem(14),
				'--font-size-headlineDescription': defaultTheme.typography.pxToRem(12),
				'--font-size-h1': defaultTheme.typography.pxToRem(24),
				'--font-size-h2': defaultTheme.typography.pxToRem(24),
				'--font-size-h3': defaultTheme.typography.pxToRem(24),
				'--font-size-h4': defaultTheme.typography.pxToRem(20),
				'--font-size-h5': defaultTheme.typography.pxToRem(18),
				'--font-size-h6': defaultTheme.typography.pxToRem(18),
				'--font-size-body1': defaultTheme.typography.pxToRem(12),
				'--font-size-body2': defaultTheme.typography.pxToRem(14),
				'--font-size-body3': defaultTheme.typography.pxToRem(14),
				'--font-size-body16': defaultTheme.typography.pxToRem(16),
				'--font-size-button': defaultTheme.typography.pxToRem(14),
				'--font-size-caption': defaultTheme.typography.pxToRem(10),
				'--font-size-subtitle1': defaultTheme.typography.pxToRem(14),
				// font-family
				'--font-garamond': '"adobe-garamond-pro"',
				'--font-base': '"Helvetica Neue"',
				'--font-base-bold': 'var(--font-base)',
				'--font-base-light': '"Helvetica Neue Light"',
				// colors
				'--color-brand': '#000000',
				'--color-primary': '#000000',
				'--color-secondary': '#ffffff',
				'--color-grey0': '#F7F7F7',
				'--color-grey10': '#2A2A2A',
				'--color-grey20': '#525252',
				'--color-grey30': '#757575',
				'--color-grey40': '#9A9A9A',
				'--color-grey50': '#B8B8B8',
				'--color-grey60': '#CFCFCF',
				'--color-grey70': '#E1E1E1',
				'--color-grey80': '#EEEEEE',
				'--color-grey90': '#F8F8F8',
				'--color-grey100': '#F2F2F2',
				'--color-grey110': '#F2F1EF',
				'--color-grey120': '#A0A0A0',
				'--color-grey130': '#373737',
				'--color-grey140': '#7F7F7F',
				'--color-brown': '#704214',
				'--color-green': '#008000',
				'--color-blue': '#0D31AB',
				'--color-purple': '#800080',
				'--color-pink': '#FFB6C1',
				'--color-red': '#D13131',
				'--color-orange': '#F28413',
				'--color-yellow': '#FFE400',
				'--color-white': '#FFFFFF',
				'--color-black': '#000000',
				// buttons
				'--button-letter-spacing': 'var(--letter-spacing-body)',
				'--button-primary-size': defaultTheme.typography.pxToRem(14),
				'--button-primary-color': 'var(--color-secondary)',
				'--button-primary-bgcolor': 'var(--color-primary)',
				'--button-primary-hover-bgcolor': 'var(--color-grey10)',
				'--button-secondary-size': defaultTheme.typography.pxToRem(14),
				'--button-secondary-color': 'var(--color-primary)',
				'--button-secondary-bgcolor': 'var(--color-secondary)',
				// animations
				'--transition': '.3s cubic-bezier(0.4, 0, 0.2, 1) 0s',
				'--swiper-theme-color': 'var(--color-primary)',
				'--maps-cluster-bg-color': 'var(--color-primary)',

				[defaultTheme.breakpoints.up('md')]: {
					'--font-size-headlinebig': defaultTheme.typography.pxToRem(32),
				},
			},
			html: {
				...fonts.helveticaNeue,

				'&::-webkit-scrollbar': {
					width: '7px',
					height: '5px',
					backgroundColor: 'var(--color-secondary)',
				},

				'&::-webkit-scrollbar-track': {
					borderRadius: '10px',
					backgroundColor: 'var(--color-secondary)',
				},

				'&::-webkit-scrollbar-thumb': {
					background: 'var(--color-grey140)',
					borderRadius: '3.5px',
				},

				'&::-webkit-scrollbar-thumb:hover': {
					background: 'var(--color-grey130)',
				},
			},
			body: {
				'--global-scrollbar-width': 'calc(var(--spacing) * 1.25)',
				...fonts.helveticaNeue,
				overflowX: 'hidden',
				textTransform: 'lowercase',
				color: 'var(--color-primary)',

				'input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active':
					{
						WebkitBoxShadow: '0 0 0 30px white inset !important',
						WebkitTextFillColor: 'var(--color-primary) !important',
					},

				'& .d-none-desktop': {
					[defaultTheme.breakpoints.up('md')]: {
						display: 'none !important',
					},
				},

				'& .cart-page': {
					'> .MuiContainer-root.main-layout-container': {
						padding: '0',
						backgroundColor: 'var(--color-grey110)',
						minHeight: 'calc(100dvh - var(--header-height))',
						h1: {
							padding: '0 20px',
						},
						[defaultTheme.breakpoints.up('md')]: {
							padding: '0 calc(var(--spacing) * 1.75)',
							h1: {
								padding: 0,
							},
						},
					},
					footer: {
						display: 'none',
					},
				},

				'& .checkout-page': {
					backgroundColor: 'var(--color-grey110)',
					h1: {
						visibility: 'hidden',
						display: 'none',
						[defaultTheme.breakpoints.down('md')]: {
							margin: '0 auto',
						},
					},
				},

				'& .checkout-success-page': {
					backgroundColor: 'var(--color-grey110)',
					minHeight: '100vh',
				},

				'& .MuiContainer-root.main-layout-container': {
					marginTop: 0,
				},

				'& .main-layout.guest-order-page': {
					'& .main-layout-container': {
						background: 'var(--color-grey0)',
					},
				},

				'& .main-layout.bottom-sheet-opened': {
					//scrollbarWidth: 'thin',
					//scrollbarColor: 'var(--color-brand) var(--color-grey0)', // thumb, track

					'&::-webkit-scrollbar': {
						width: '7px',
						height: '5px',
						backgroundColor: 'var(--color-secondary)',
					},

					'&::-webkit-scrollbar-track': {
						borderRadius: '10px',
						backgroundColor: 'var(--color-secondary)',
					},

					'&::-webkit-scrollbar-thumb': {
						background: 'var(--color-grey140)',
						borderRadius: '3.5px',
					},

					'&::-webkit-scrollbar-thumb:hover': {
						background: 'var(--color-grey130)',
					},
				},

				// Klarna global styles
				'.KlarnaPlacement-root': {
					'klarna-placement::part(osm-container)': {
						padding: 0,
					},
					'klarna-placement::part(osm-message)': {
						textTransform: 'lowercase',
						fontFamily: 'var(--font-base)',
						letterSpacing: 'var(--letter-spacing)',
					},
					'klarna-placement::part(osm-logo)': {
						textTransform: 'initial',
					},
					'klarna-placement::part(osm-cta)': {
						display: 'none',
					},
					'klarna-placement #test-badge-element-id': {
						display: 'none !important',
					},
				},

				'.cs_size_guide': {
					margin: 'auto',
					maxWidth: '650px',
				},
				'.cs_size_guide_table': {
					maxWidth: '100%',
				},
				'#iubenda-cs-banner': {
					fontSize: '16px !important',
					background: 'none !important',
					lineHeight: '1.5 !important',
					position: 'fixed !important',
					zIndex: '99999998 !important',
					top: '0 !important',
					left: '0 !important',
					width: '100% !important',
					height: '100% !important',
					border: '0 !important',
					margin: '0 !important',
					padding: '0 !important',
					overflow: 'hidden !important',
					display: 'flex !important',
					willChange: 'opacity, visibility',
					opacity: '0 !important',
					visibility: 'hidden !important',
					pointerEvents: 'none !important',
					WebkitTransition: 'opacity 0.4s ease, visibility 0.4s ease !important',
					OTransition: 'opacity 0.4s ease, visibility 0.4s ease !important',
					transition: 'opacity 0.4s ease, visibility 0.4s ease !important',
				},
				'#iubenda-cs-banner [class*=" iub"],#iubenda-cs-banner [class^=iub],#iubenda-cs-banner .iubenda-banner-content:not(.iubenda-custom-content) *':
					{
						fontSize: '12px !important',
						fontFamily: 'var(--font-base) !important',
						width: 'auto !important',
						WebkitAppearance: 'none !important',
						MozAppearance: 'none !important',
						appearance: 'none !important',
						background: 'none !important',
						WebkitBoxSizing: 'border-box !important',
						boxSizing: 'border-box !important',
						WebkitTapHighlightColor: 'rgba(0, 0, 0, 0) !important',
						WebkitBackfaceVisibility: 'hidden !important',
						backfaceVisibility: 'hidden !important',
						textDecoration: 'none !important',
						color: 'currentColor !important',
						backgroundAttachment: 'scroll !important',
						backgroundColor: 'transparent !important',
						backgroundImage: 'none !important',
						backgroundPosition: '0 0 !important',
						backgroundRepeat: 'repeat !important',
						border: '0 !important',
						borderColor: '#000 !important',
						borderRadius: '0 !important',
						borderStyle: 'none !important',
						borderWidth: 'medium !important',
						bottom: 'auto !important',
						clear: 'none !important',
						clip: 'auto !important',
						counterIncrement: 'none !important',
						counterReset: 'none !important',
						direction: 'inherit !important',
						cssFloat: 'none !important',
						fontStyle: 'inherit !important',
						fontVariant: 'normal !important',
						fontWeight: 'inherit !important',
						height: 'auto !important',
						left: 'auto !important',
						letterSpacing: '0.07em !important',
						lineHeight: 'inherit !important',
						listStyleType: 'inherit !important',
						listStylePosition: 'outside !important',
						listStyleImage: 'none !important',
						margin: '0 !important',
						maxHeight: 'none !important',
						maxWidth: 'none !important',
						minHeight: '0 !important',
						minWidth: '0 !important',
						opacity: 1,
						outline: '0 !important',
						overflow: 'visible !important',
						padding: '0 !important',
						position: 'static !important',
						quotes: '"""" !important',
						right: 'auto !important',
						tableLayout: 'auto !important',
						textAlign: 'left !important',
						textIndent: '0 !important',
						textTransform: 'none !important',
						top: 'auto !important',
						unicodeBidi: 'normal !important',
						verticalAlign: 'baseline !important',
						visibility: 'inherit !important',
						whiteSpace: 'normal !important',
						wordSpacing: 'normal !important',
						zIndex: 'auto !important',
						backgroundOrigin: 'padding-box !important',
						backgroundClip: 'border-box !important',
						backgroundSize: 'auto !important',
						OBorderImage: 'none !important',
						borderImage: 'none !important',
						WebkitBoxShadow: 'none !important',
						boxShadow: 'none !important',
						WebkitColumnCount: 'auto !important',
						MozColumnCount: 'auto !important',
						columnCount: 'auto !important',
						WebkitColumnGap: 'normal !important',
						MozColumnGap: 'normal !important',
						columnGap: 'normal !important',
						WebkitColumnRule: 'medium none #000 !important',
						MozColumnRule: 'medium none #000 !important',
						columnRule: 'medium none #000 !important',
						WebkitColumnSpan: 'none !important',
						MozColumnSpan: 'none !important',
						columnSpan: 'none !important',
						WebkitColumnWidth: 'auto !important',
						MozColumnWidth: 'auto !important',
						columnWidth: 'auto !important',
						WebkitFontFeatureSettings: 'normal !important',
						fontFeatureSettings: 'normal !important',
						overflowX: 'visible !important',
						overflowY: 'visible !important',
						WebkitHyphens: 'manual !important',
						msHyphens: 'manual !important',
						hyphens: 'manual !important',
						WebkitPerspective: 'none !important',
						perspective: 'none !important',
						WebkitPerspectiveOrigin: '50% 50% !important',
						perspectiveOrigin: '50% 50% !important',
						textShadow: 'none !important',
						WebkitTransition: 'all 0s ease 0s !important',
						OTransition: 'all 0s ease 0s !important',
						transition: 'all 0s ease 0s !important',
						WebkitTransform: 'none !important',
						msTransform: 'none !important',
						transform: 'none !important',
						WebkitTransformOrigin: '50% 50% !important',
						msTransformOrigin: '50% 50% !important',
						transformOrigin: '50% 50% !important',
						WebkitTransformStyle: 'flat !important',
						transformStyle: 'flat !important',
						wordBreak: 'normal !important',
						msTextSizeAdjust: '100%',
						WebkitTextSizeAdjust: '100%',
					},
				'#iubenda-cs-banner.iubenda-cs-overlay:before': {
					content: `''`,
					position: 'fixed !important',
					top: '0 !important',
					left: '0 !important',
					width: '100% !important',
					height: '100% !important',
					backgroundColor: 'rgba(0, 0, 0, 0.5) !important',
					zIndex: '1 !important',
					pointerEvents: 'auto !important',
				},
				'#iubenda-cs-banner.iubenda-cs-center': {
					WebkitBoxAlign: 'center !important',
					msFlexAlign: 'center !important',
					alignItems: 'center !important',
					WebkitBoxPack: 'center !important',
					msFlexPack: 'center !important',
					justifyContent: 'center !important',
				},
				'#iubenda-cs-banner.iubenda-cs-top': {
					WebkitBoxAlign: 'start !important',
					msFlexAlign: 'start !important',
					alignItems: 'flex-start !important',
				},
				'#iubenda-cs-banner.iubenda-cs-bottom': {
					WebkitBoxAlign: 'end !important',
					msFlexAlign: 'end !important',
					alignItems: 'flex-end !important',
				},
				'#iubenda-cs-banner.iubenda-cs-left': {
					WebkitBoxPack: 'start !important',
					msFlexPack: 'start !important',
					justifyContent: 'flex-start !important',
				},
				'#iubenda-cs-banner.iubenda-cs-right': {
					WebkitBoxPack: 'end !important',
					msFlexPack: 'end !important',
					justifyContent: 'flex-end !important',
				},
				'#iubenda-cs-banner.iubenda-cs-visible': {
					opacity: '1 !important',
					visibility: 'visible !important',
				},
				'#iubenda-cs-banner.iubenda-cs-visible>*': {
					pointerEvents: 'auto !important',
				},
				'#iubenda-cs-banner.iubenda-cs-slidein .iubenda-cs-container': {
					WebkitTransition: '-webkit-transform 0.4s ease !important',
					transition: [
						'-webkit-transform 0.4s ease !important',
						'transform 0.4s ease !important',
						'transform 0.4s ease, -webkit-transform 0.4s ease !important',
					],
					OTransition: 'transform 0.4s ease !important',
				},
				'#iubenda-cs-banner.iubenda-cs-slidein.iubenda-cs-top .iubenda-cs-container': {
					WebkitTransform: 'translateY(-48px) !important',
					msTransform: 'translateY(-48px) !important',
					transform: 'translateY(-48px) !important',
				},
				'#iubenda-cs-banner.iubenda-cs-slidein.iubenda-cs-bottom .iubenda-cs-container': {
					WebkitTransform: 'translateY(48px) !important',
					msTransform: 'translateY(48px) !important',
					transform: 'translateY(48px) !important',
				},
				'#iubenda-cs-banner.iubenda-cs-slidein.iubenda-cs-visible .iubenda-cs-container': {
					WebkitTransform: 'translateY(0) !important',
					msTransform: 'translateY(0) !important',
					transform: 'translateY(0) !important',
				},
				'#iubenda-cs-banner .iubenda-cs-container': {
					position: 'relative !important',
					zIndex: '2 !important',
				},
				'#iubenda-cs-banner .iubenda-cs-brand': {
					display: 'flex !important',
					padding: '16px !important',
					msFlexNegative: '0 !important',
					flexShrink: '0 !important',
				},
				'#iubenda-cs-banner .iubenda-cs-brand>div': {
					display: 'flex !important',
					WebkitBoxPack: 'start !important',
					msFlexPack: 'start !important',
					justifyContent: 'flex-start !important',
				},
				'#iubenda-cs-banner .iubenda-cs-brand img': {
					maxWidth: '192px !important',
					maxHeight: '56px !important',
				},
				'#iubenda-cs-banner .iubenda-cs-content': {
					position: 'relative !important',
					zIndex: '1 !important',
					overflow: 'hidden !important',
					WebkitTransition: '-webkit-transform 0.4s ease !important',
					transition: [
						'-webkit-transform 0.4s ease !important',
						'transform 0.4s ease !important',
						'transform 0.4s ease, -webkit-transform 0.4s ease !important',
					],
					OTransition: 'transform 0.4s ease !important',
					backgroundColor: '#ffffff !important',
					color: '#000000 !important',
				},
				'#iubenda-cs-banner .iubenda-cs-rationale': {
					position: 'relative !important',
					display: 'flex !important',
					WebkitBoxOrient: 'vertical !important',
					WebkitBoxDirection: 'normal !important',
					msFlexDirection: 'column !important',
					flexDirection: 'column !important',
				},
				'#iubenda-cs-banner .iubenda-cs-close-btn': {
					position: 'absolute !important',
					top: '-2px !important',
					zIndex: '2 !important',
					padding: '16px !important',
					right: '0 !important',
					minWidth: '48px !important',
					height: '48px !important',
					fontSize: '24px !important',
					lineHeight: '0 !important',
					fontWeight: 'lighter !important',
					cursor: 'pointer !important',
					textAlign: 'center !important',
				},
				'#iubenda-cs-banner .iubenda-cs-close-btn:hover': {
					opacity: '0.5 !important',
				},
				'#iubenda-cs-banner .iubenda-banner-content': {
					fontWeight: '300 !important',
					margin: '16px !important',
					marginBottom: '0 !important',
					WebkitBoxFlex: '1 !important',
					msFlex: '1 1 auto !important',
					flex: '1 1 auto !important',
					overflowY: 'auto !important',
					maskImage: 'linear-gradient(to top, rgba(0, 0, 0, 0) 0%, black 16px) !important',
					WebkitMaskImage: 'linear-gradient(to top, rgba(0, 0, 0, 0) 0%, black 16px) !important',
					paddingBottom: '32px !important',
				},
				'#iubenda-cs-banner .iubenda-banner-content-padded': {
					paddingRight: '32px !important',
				},
				'#iubenda-cs-banner .iubenda-banner-content a': {
					cursor: 'pointer !important',
					color: 'currentColor !important',
					opacity: '0.7 !important',
					textDecoration: 'underline !important',
				},
				'#iubenda-cs-banner .iubenda-banner-content a:hover': {
					opacity: '1 !important',
				},
				'#iubenda-cs-banner #iubenda-cs-title': {
					fontWeight: 'bold !important',
					marginBottom: '16px !important',
					display: 'none !important',
				},
				'#iubenda-cs-banner .iubenda-cs-opt-group': {
					margin: '16px !important',
					zIndex: '1 !important',
					display: 'flex !important',
					marginTop: '0 !important',
					msFlexNegative: '0 !important',
					flexShrink: '0 !important',
					color: '#000000 !important',
				},
				'#iubenda-cs-banner .iubenda-cs-opt-group>div': {
					display: 'flex !important',
				},
				'@media (min-width: 640px)': [
					{
						'#iubenda-cs-banner .iubenda-cs-opt-group': {
							WebkitBoxAlign: 'center !important',
							msFlexAlign: 'center !important',
							alignItems: 'center !important',
							WebkitBoxPack: 'justify !important',
							msFlexPack: 'justify !important',
							justifyContent: 'space-between !important',
						},
						'#iubenda-cs-banner .iubenda-cs-opt-group-custom': {
							marginRight: 'auto !important',
							msFlexItemAlign: 'start !important',
							alignSelf: 'start !important',
							WebkitBoxPack: 'start !important',
							msFlexPack: 'start !important',
							justifyContent: 'flex-start !important',
						},
						'#iubenda-cs-banner .iubenda-cs-opt-group-consent': {
							marginLeft: 'auto !important',
							msFlexItemAlign: 'end !important',
							alignSelf: 'end !important',
							WebkitBoxPack: 'end !important',
							msFlexPack: 'end !important',
							justifyContent: 'flex-end !important',
						},
					},
					{
						'#iubenda-cs-banner .iubenda-cs-opt-group button:not(:last-of-type)': {
							marginRight: '8px !important',
						},
					},
					{
						'#iubenda-cs-banner.iubenda-cs-default-floating:not(.iubenda-cs-top):not(.iubenda-cs-center) .iubenda-cs-container,    #iubenda-cs-banner.iubenda-cs-default-floating:not(.iubenda-cs-bottom):not(.iubenda-cs-center) .iubenda-cs-container,    #iubenda-cs-banner.iubenda-cs-default-floating.iubenda-cs-center:not(.iubenda-cs-top):not(.iubenda-cs-bottom) .iubenda-cs-container':
							{
								width: '480px !important',
							},
					},
				],
				'@media (max-width: 639px)': [
					{
						'#iubenda-cs-banner .iubenda-cs-opt-group': {
							margin: '12px !important',
							WebkitBoxOrient: 'vertical !important',
							WebkitBoxDirection: 'normal !important',
							msFlexDirection: 'column !important',
							flexDirection: 'column !important',
						},
						'#iubenda-cs-banner .iubenda-cs-opt-group-custom': {
							WebkitBoxOrdinalGroup: '3',
							msFlexOrder: '2',
							order: 2,
						},
						'#iubenda-cs-banner .iubenda-cs-opt-group-consent': {
							WebkitBoxOrdinalGroup: '2',
							msFlexOrder: '1',
							order: 1,
						},
					},
					{
						'#iubenda-cs-banner .iubenda-cs-opt-group button': {
							padding: '8px 24px !important',
							width: '100% !important',
							display: 'block',
							textAlign: 'center !important',
							margin: '6px 3px !important',
						},
					},
				],
				'#iubenda-cs-banner .iubenda-cs-opt-group button': {
					WebkitAppearance: 'none !important',
					MozAppearance: 'none !important',
					appearance: 'none !important',
					padding: '8px 32px !important',
					borderRadius: '0px !important',
					cursor: 'pointer !important',
					fontWeight: 'bold !important',
					fontSize: '12px !important',
					marginTop: '4px !important',
					marginBottom: '4px !important',
					textAlign: 'center !important',
					textTransform: 'lowercase !important',
					backgroundColor: '#000000 !important',
					color: '#FFFFFF !important',
				},
				'#iubenda-cs-banner .iubenda-cs-opt-group button:focus': {
					opacity: '0.8 !important',
				},
				'#iubenda-cs-banner .iubenda-cs-opt-group button:hover': {
					opacity: '0.8 !important',
				},
				'#iubenda-cs-banner.iubenda-cs-default .iubenda-cs-brand': {
					margin: '0 -8px 0 !important',
				},
				'@media (max-width: 991px)': [
					{
						'#iubenda-cs-banner.iubenda-cs-default .iubenda-cs-brand': {
							margin: '-8px -8px 0 !important',
						},
					},
					{
						'#iubenda-cs-banner.iubenda-cs-default .iubenda-cs-brand div': {
							margin: '0 8px !important',
						},
					},
					{
						'#iubenda-cs-banner.iubenda-cs-default .iubenda-cs-content': {
							padding: '8px !important',
						},
					},
					{
						'#iubenda-cs-banner.iubenda-cs-default-floating.iubenda-cs-center.iubenda-cs-top .iubenda-cs-container,    #iubenda-cs-banner.iubenda-cs-default-floating.iubenda-cs-center.iubenda-cs-bottom .iubenda-cs-container':
							{
								width: '100% !important',
							},
					},
				],
				'@media (min-width: 992px)': [
					{
						'#iubenda-cs-banner.iubenda-cs-default .iubenda-cs-brand div': {
							margin: '0 auto !important',
							width: 'calc(992px - 32px) !important',
						},
					},
					{
						'#iubenda-cs-banner.iubenda-cs-default .iubenda-cs-rationale': {
							width: '100% !important',
							margin: '16px auto !important',
						},
					},
					{
						'#iubenda-cs-banner.iubenda-cs-default-floating .iubenda-cs-container': {
							width: '992px !important',
						},
					},
					{
						'#iubenda-cs-banner.iubenda-cs-default-floating .iubenda-cs-content': {
							borderRadius: '0px !important',
							margin: '16px !important',
						},
					},
					{
						'.iubenda-mobile-sdk #iubenda-cs-banner .iubenda-banner-content,    .iubenda-mobile-sdk #iubenda-cs-banner .iubenda-cs-opt-group':
							{
								margin: '24px !important',
							},
						'.iubenda-mobile-sdk #iubenda-cs-banner.iubenda-cs-default .iubenda-cs-rationale': {
							width: '100% !important',
							margin: '0 !important',
						},
					},
				],
				'#iubenda-cs-banner.iubenda-cs-default .iubenda-cs-container': {
					width: '480px !important',
				},
				'#iubenda-cs-banner.iubenda-cs-default-floating .iubenda-cs-brand': {
					margin: '-8px -8px 0 !important',
				},
				'#iubenda-cs-banner.iubenda-cs-default-floating .iubenda-cs-brand div': {
					margin: '8px !important',
				},
				'#iubenda-cs-banner.iubenda-cs-default-floating:not(.iubenda-cs-top):not(.iubenda-cs-center) .iubenda-cs-opt-group,#iubenda-cs-banner.iubenda-cs-default-floating:not(.iubenda-cs-bottom):not(.iubenda-cs-center) .iubenda-cs-opt-group,#iubenda-cs-banner.iubenda-cs-default-floating.iubenda-cs-center:not(.iubenda-cs-top):not(.iubenda-cs-bottom) .iubenda-cs-opt-group':
					{
						WebkitBoxOrient: 'vertical !important',
						WebkitBoxDirection: 'normal !important',
						msFlexDirection: 'row !important',
						flexDirection: 'row !important',
						gap: '20px !important',
					},
				'#iubenda-cs-banner.iubenda-cs-default-floating:not(.iubenda-cs-top):not(.iubenda-cs-center) .iubenda-cs-opt-group>div,#iubenda-cs-banner.iubenda-cs-default-floating:not(.iubenda-cs-bottom):not(.iubenda-cs-center) .iubenda-cs-opt-group>div,#iubenda-cs-banner.iubenda-cs-default-floating.iubenda-cs-center:not(.iubenda-cs-top):not(.iubenda-cs-bottom) .iubenda-cs-opt-group>div':
					{
						width: '100% !important',
					},
				'#iubenda-cs-banner.iubenda-cs-default-floating:not(.iubenda-cs-top):not(.iubenda-cs-center) .iubenda-cs-opt-group button,#iubenda-cs-banner.iubenda-cs-default-floating:not(.iubenda-cs-bottom):not(.iubenda-cs-center) .iubenda-cs-opt-group button,#iubenda-cs-banner.iubenda-cs-default-floating.iubenda-cs-center:not(.iubenda-cs-top):not(.iubenda-cs-bottom) .iubenda-cs-opt-group button':
					{
						display: 'block !important',
						width: '100% !important',
						textAlign: 'center !important',
					},
				'#iubenda-cs-banner.iubenda-cs-default-floating:not(.iubenda-cs-top):not(.iubenda-cs-center) .iubenda-cs-opt-group-custom,#iubenda-cs-banner.iubenda-cs-default-floating:not(.iubenda-cs-bottom):not(.iubenda-cs-center) .iubenda-cs-opt-group-custom,#iubenda-cs-banner.iubenda-cs-default-floating.iubenda-cs-center:not(.iubenda-cs-top):not(.iubenda-cs-bottom) .iubenda-cs-opt-group-custom':
					{
						WebkitBoxOrdinalGroup: '3',
						msFlexOrder: '2',
						order: 2,
					},
				'#iubenda-cs-banner.iubenda-cs-default-floating:not(.iubenda-cs-top):not(.iubenda-cs-center) .iubenda-cs-opt-group-consent,#iubenda-cs-banner.iubenda-cs-default-floating:not(.iubenda-cs-bottom):not(.iubenda-cs-center) .iubenda-cs-opt-group-consent,#iubenda-cs-banner.iubenda-cs-default-floating.iubenda-cs-center:not(.iubenda-cs-top):not(.iubenda-cs-bottom) .iubenda-cs-opt-group-consent':
					{
						WebkitBoxOrdinalGroup: '2',
						msFlexOrder: '1',
						order: 1,
					},
				'#iubenda-cs-banner.iubenda-cs-default-floating .iubenda-cs-content': {
					WebkitBoxShadow: '0 8px 48px rgba(0, 0, 0, 0.15) !important',
					boxShadow: '0 8px 48px rgba(0, 0, 0, 0.15) !important',
					padding: '8px !important',
				},
				'#iubenda-cs-banner.iubenda-cs-fix-height .iubenda-cs-container,#iubenda-cs-banner.iubenda-cs-fix-height .iubenda-cs-content,#iubenda-cs-banner.iubenda-cs-fix-height .iubenda-cs-rationale':
					{
						height: '100% !important',
					},
				'#iubenda-cs-banner.iubenda-cs-fix-height.iubenda-cs-default-floating .iubenda-cs-content': {
					height: 'calc(100% - 32px) !important',
				},
				'#iubenda-cs-banner.iubenda-cs-fix-height .iubenda-cs-brand img': {
					maxWidth: '75% !important',
				},
				'#iubenda-cs-banner .iubenda-cs-opt-group button.iubenda-cs-btn-primary': {
					backgroundColor: '#000000 !important',
					color: '#FFFFFF !important',
				},
				'.iubenda-tp-btn:not([data-tp-nostyle])': {
					fontSize: '14px !important',
					width: 'auto !important',
					WebkitAppearance: 'none !important',
					MozAppearance: 'none !important',
					appearance: 'none !important',
					background: 'none !important',
					WebkitBoxSizing: 'border-box !important',
					boxSizing: 'border-box !important',
					WebkitTapHighlightColor: 'rgba(0, 0, 0, 0) !important',
					WebkitBackfaceVisibility: 'hidden !important',
					backfaceVisibility: 'hidden !important',
					fontFamily: '-apple-system, sans-serif !important',
					textDecoration: 'none !important',
					color: 'rgba(0, 0, 0, 0.65) !important',
					backgroundAttachment: 'scroll !important',
					backgroundColor: 'white !important',
					backgroundImage: 'none !important',
					backgroundPosition: '0 0 !important',
					backgroundRepeat: 'repeat !important',
					border: '0 !important',
					borderColor: '#000 !important',
					borderRadius: '0 !important',
					borderStyle: 'none !important',
					borderWidth: 'medium !important',
					bottom: 'auto !important',
					clear: 'none !important',
					clip: 'auto !important',
					counterIncrement: 'none !important',
					counterReset: 'none !important',
					cursor: 'auto !important',
					direction: 'inherit !important',
					cssFloat: 'none !important',
					fontStyle: 'inherit !important',
					fontVariant: 'normal !important',
					fontWeight: 'inherit !important',
					height: 'auto !important',
					left: 'auto !important',
					letterSpacing: 'normal !important',
					lineHeight: 'inherit !important',
					listStyleType: 'inherit !important',
					listStylePosition: 'outside !important',
					listStyleImage: 'none !important',
					margin: '0 !important',
					maxHeight: 'none !important',
					maxWidth: 'none !important',
					minHeight: '0 !important',
					minWidth: '0 !important',
					opacity: 1,
					outline: '0 !important',
					overflow: 'visible !important',
					padding: '0 !important',
					position: 'static !important',
					right: 'auto !important',
					tableLayout: 'auto !important',
					textAlign: 'left !important',
					textIndent: '0 !important',
					textTransform: 'none !important',
					top: 'auto !important',
					unicodeBidi: 'normal !important',
					verticalAlign: 'middle !important',
					visibility: 'inherit !important',
					whiteSpace: 'normal !important',
					wordSpacing: 'normal !important',
					zIndex: 'auto !important',
					backgroundOrigin: 'padding-box !important',
					backgroundClip: 'border-box !important',
					backgroundSize: 'auto !important',
					OBorderImage: 'none !important',
					borderImage: 'none !important',
					WebkitBoxShadow: '0 0 0 1px rgba(0, 0, 0, 0.15) !important',
					boxShadow: '0 0 0 1px rgba(0, 0, 0, 0.15) !important',
					WebkitColumnCount: 'auto !important',
					MozColumnCount: 'auto !important',
					columnCount: 'auto !important',
					WebkitColumnGap: 'normal !important',
					MozColumnGap: 'normal !important',
					columnGap: 'normal !important',
					WebkitColumnRule: 'medium none #000 !important',
					MozColumnRule: 'medium none #000 !important',
					columnRule: 'medium none #000 !important',
					WebkitColumnSpan: 'none !important',
					MozColumnSpan: 'none !important',
					columnSpan: 'none !important',
					WebkitColumnWidth: 'auto !important',
					MozColumnWidth: 'auto !important',
					columnWidth: 'auto !important',
					WebkitFontFeatureSettings: 'normal !important',
					fontFeatureSettings: 'normal !important',
					overflowX: 'visible !important',
					overflowY: 'visible !important',
					WebkitHyphens: 'manual !important',
					msHyphens: 'manual !important',
					hyphens: 'manual !important',
					WebkitPerspective: 'none !important',
					perspective: 'none !important',
					WebkitPerspectiveOrigin: '50% 50% !important',
					perspectiveOrigin: '50% 50% !important',
					textShadow: 'none !important',
					WebkitTransition: 'all 0s ease 0s !important',
					OTransition: 'all 0s ease 0s !important',
					transition: 'all 0s ease 0s !important',
					WebkitTransform: 'none !important',
					msTransform: 'none !important',
					transform: 'none !important',
					WebkitTransformOrigin: '50% 50% !important',
					msTransformOrigin: '50% 50% !important',
					transformOrigin: '50% 50% !important',
					WebkitTransformStyle: 'flat !important',
					transformStyle: 'flat !important',
					wordBreak: 'normal !important',
					msTextSizeAdjust: '100%',
					WebkitTextSizeAdjust: '100%',
					display: 'inline-block !important',
				},
				'.iubenda-tp-btn[data-tp-icon]': {
					backgroundImage:
						"url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'%3E%3Cpath fill='%231CC691' fill-rule='evenodd' d='M16 7a4 4 0 0 1 2.627 7.016L19.5 25h-7l.873-10.984A4 4 0 0 1 16 7z'/%3E%3C/svg%3E\") !important",
					backgroundRepeat: 'no-repeat !important',
					backgroundSize: '32px 32px !important',
					backgroundPosition: 'top .5px left 1px !important',
				},
				'.iubenda-tp-btn[data-tp-circle]': { borderRadius: '32px !important' },
				'.iubenda-tp-btn[data-tp-label]:after': {
					content: '`attr(data-tp-label) !important`',
					padding: '0 16px !important',
					whiteSpace: 'nowrap !important',
				},
				'.iubenda-tp-btn[data-tp-label][data-tp-icon]:after': {
					paddingLeft: 'calc(16px + 8px + 8px) !important',
				},
				'.iubenda-tp-btn[data-tp-float]': {
					position: 'fixed !important',
					zIndex: '2147483647 !important',
				},
				'.iubenda-tp-btn[data-tp-float]:not([data-tp-anchored])': {
					margin: '16px !important',
				},
				'.iubenda-tp-btn[data-tp-float][data-tp-anchored]': {
					margin: '0 16px !important',
					borderRadius: '6px !important',
				},
				'.iubenda-tp-btn[data-tp-float][data-tp-anchored][data-tp-hover][data-tp-float="center-left"],.iubenda-tp-btn[data-tp-float][data-tp-anchored][data-tp-hover][data-tp-float="center-right"],.iubenda-tp-btn[data-tp-float][data-tp-anchored]:not([data-tp-hover]):not([data-tp-label])[data-tp-float="center-left"],.iubenda-tp-btn[data-tp-float][data-tp-anchored]:not([data-tp-hover]):not([data-tp-label])[data-tp-float="center-right"]':
					{
						margin: '0 !important',
						top: '75% !important',
						WebkitTransform: 'translateY(-50%) !important',
						msTransform: 'translateY(-50%) !important',
						transform: 'translateY(-50%) !important',
					},
				'.iubenda-tp-btn[data-tp-float][data-tp-anchored][data-tp-hover][data-tp-float="center-left"],.iubenda-tp-btn[data-tp-float][data-tp-anchored]:not([data-tp-hover]):not([data-tp-label])[data-tp-float="center-left"]':
					{
						left: '0 !important',
						borderTopLeftRadius: '0 !important',
						borderBottomLeftRadius: '0 !important',
						borderLeft: '0 !important',
					},
				'.iubenda-tp-btn[data-tp-float][data-tp-anchored][data-tp-hover][data-tp-float="center-right"],.iubenda-tp-btn[data-tp-float][data-tp-anchored]:not([data-tp-hover]):not([data-tp-label])[data-tp-float="center-right"]':
					{
						right: '0 !important',
						borderTopRightRadius: '0 !important',
						borderBottomRightRadius: '0 !important',
						borderRight: '0 !important',
					},
				'.iubenda-tp-btn[data-tp-float][data-tp-anchored]:not([data-tp-hover])[data-tp-label][data-tp-float="center-left"],.iubenda-tp-btn[data-tp-float][data-tp-anchored]:not([data-tp-hover])[data-tp-label][data-tp-float="center-right"]':
					{
						margin: '0 !important',
						top: '50% !important',
						borderBottomLeftRadius: '0 !important',
						borderBottomRightRadius: '0 !important',
						borderBottom: '0 !important',
						WebkitTransformOrigin: 'bottom !important',
						msTransformOrigin: 'bottom !important',
						transformOrigin: 'bottom !important',
					},
				'.iubenda-tp-btn[data-tp-float][data-tp-anchored]:not([data-tp-hover])[data-tp-label][data-tp-float="center-left"]':
					{
						left: '0 !important',
						WebkitTransform: 'translateY(-50%) rotate(90deg) !important',
						msTransform: 'translateY(-50%) rotate(90deg) !important',
						transform: 'translateY(-50%) rotate(90deg) !important',
						WebkitTransformOrigin: 'left bottom !important',
						msTransformOrigin: 'left bottom !important',
						transformOrigin: 'left bottom !important',
					},
				'.iubenda-tp-btn[data-tp-float][data-tp-anchored]:not([data-tp-hover])[data-tp-label][data-tp-float="center-right"]':
					{
						right: '0 !important',
						WebkitTransform: 'translateY(-50%) rotate(-90deg) !important',
						msTransform: 'translateY(-50%) rotate(-90deg) !important',
						transform: 'translateY(-50%) rotate(-90deg) !important',
						WebkitTransformOrigin: 'right bottom !important',
						msTransformOrigin: 'right bottom !important',
						transformOrigin: 'right bottom !important',
					},
				'.iubenda-tp-btn[data-tp-float][data-tp-float="bottom-left"],.iubenda-tp-btn[data-tp-float][data-tp-float="bottom-right"]':
					{
						bottom: '0 !important',
					},
				'.iubenda-tp-btn[data-tp-float][data-tp-float="bottom-left"][data-tp-anchored],.iubenda-tp-btn[data-tp-float][data-tp-float="bottom-right"][data-tp-anchored]':
					{
						borderBottomLeftRadius: '0 !important',
						borderBottomRightRadius: '0 !important',
						borderBottom: '0 !important',
					},
				'.iubenda-tp-btn[data-tp-float][data-tp-float="top-left"],.iubenda-tp-btn[data-tp-float][data-tp-float="top-right"]':
					{
						top: '0 !important',
					},
				'.iubenda-tp-btn[data-tp-float][data-tp-float="top-left"][data-tp-anchored],.iubenda-tp-btn[data-tp-float][data-tp-float="top-right"][data-tp-anchored]':
					{
						borderTopLeftRadius: '0 !important',
						borderTopRightRadius: '0 !important',
						borderTop: '0 !important',
					},
				'.iubenda-tp-btn[data-tp-float][data-tp-float="top-left"],.iubenda-tp-btn[data-tp-float][data-tp-float="bottom-left"]':
					{
						left: '0 !important',
					},
				'.iubenda-tp-btn[data-tp-float][data-tp-float="top-right"],.iubenda-tp-btn[data-tp-float][data-tp-float="bottom-right"]':
					{
						right: '0 !important',
					},
				'.iubenda-tp-btn[data-tp-float][data-tp-hover][data-tp-label]:after': {
					maxWidth: '0 !important',
					overflow: 'hidden !important',
					display: 'block !important',
					padding: '0 !important',
					opacity: '0 !important',
					WebkitTransition: 'max-width 0.6s ease, padding 0.6s ease, opacity 0.6s ease !important',
					OTransition: 'max-width 0.6s ease, padding 0.6s ease, opacity 0.6s ease !important',
					transition: 'max-width 0.6s ease, padding 0.6s ease, opacity 0.6s ease !important',
				},
				'.iubenda-tp-btn[data-tp-float][data-tp-hover][data-tp-label]:hover:after': {
					maxWidth: '192px !important',
					paddingLeft: 'calc(16px + 8px + 8px) !important',
					paddingRight: '10px !important',
					opacity: '1 !important',
				},
				'.iubenda-mobile-sdk #iubenda-cs-banner.iubenda-cs-visible': {
					justifyContent: 'center !important',
					alignItems: 'center !important',
				},
				'.iubenda-mobile-sdk #iubenda-cs-banner .iubenda-cs-container': {
					maxHeight: '100% !important',
					display: 'flex !important',
				},
				'.iubenda-mobile-sdk #iubenda-cs-banner .iubenda-cs-container .iubenda-cs-content': {
					height: 'auto !important',
					borderRadius: '0 !important',
					margin: '0 !important',
				},
				'.iubenda-mobile-sdk #iubenda-cs-banner .iubenda-cs-opt-group': {
					textAlign: 'center !important',
				},
				'.iubenda-mobile-sdk #iubenda-cs-banner .iubenda-cs-close-btn': {
					display: 'none !important',
				},
				'.iubenda-mobile-sdk #iubenda-cs-banner .iubenda-cs-rationale': {
					display: 'flex !important',
					flexDirection: 'column !important',
					height: '100% !important',
				},
				'.iubenda-mobile-sdk #iubenda-cs-banner .iubenda-banner-content': {
					flex: 1,
					overflowY: 'auto !important',
					margin: '0 !important',
					padding: '16px 16px 48px !important',
					maxHeight: 'inherit !important',
					maskImage: 'linear-gradient(to top, rgba(0, 0, 0, 0) 0%, black 15%) !important',
					WebkitMaskImage: 'linear-gradient(to top, rgba(0, 0, 0, 0) 0%, black 15%) !important',
				},
				'.no-banner #iubenda-cs-banner': { display: 'none !important' },
				'.iubenda-mobile-sdk #iubenda-cs-banner.iubenda-cs-visible .iubenda-cs-container,.iubenda-mobile-sdk #iubenda-cs-banner .iubenda-cs-container .iubenda-cs-content,.iubenda-mobile-sdk #iubenda-cs-banner .iubenda-cs-rationale':
					{
						height: '100% !important',
					},
				'.iubenda-mobile-sdk #iubenda-iframe .iubenda-iframe-top-container': {
					position: 'relative !important',
				},
				'.iubenda-mobile-sdk #iubenda-iframe #iab-container': {
					position: 'absolute !important',
					top: '0 !important',
					bottom: '0 !important',
					left: '0 !important',
					right: '0 !important',
				},
				'.iubenda-mobile-sdk #iubenda-iframe #iubenda-iframe-popup': {
					height: '100% !important',
					width: '100% !important',
				},
				'.iubenda-mobile-sdk #iubenda-iframe .iubenda-iframe-close-btn': {
					display: 'none !important',
				},
				'.iubenda-mobile-sdk #iubenda-iframe': {
					backgroundColor: 'transparent !important',
				},
				'.iubenda-mobile-sdk #iubenda-iframe iframe': {
					backgroundColor: 'transparent !important',
					position: 'absolute !important',
					bottom: '0 !important',
					top: '0 !important',
				},
				'.iubenda-mobile-sdk #iubenda-iframe .iubenda-iframe-spinner': {
					top: '50px !important',
				},
				'.iubenda-mobile-sdk #iubenda-iframe .iubenda-iframe-footer': {
					zIndex: '9999 !important',
				},
				'.iubenda-mobile-sdk #iubenda-iframe.iub-popover-visible .iub-popover': {
					zIndex: '10000 !important',
				},
				'.iubenda-mobile-sdk #iubenda-iframe .iub-popover-content': {
					position: 'relative !important',
				},
				'.iubenda-mobile-sdk #iubenda-iframe .iub-popover-content>div': {
					WebkitOverflowScrolling: 'touch !important',
					position: 'absolute !important',
					top: '0 !important',
					bottom: '0 !important',
				},
				'.iubenda-mobile-sdk #purposes-container': {
					WebkitOverflowScrolling: 'touch !important',
					position: 'absolute !important',
					top: '0 !important',
					bottom: '0 !important',
					left: '0 !important',
					right: '0 !important',
					overflow: 'auto !important',
				},
			},
			hr: {
				border: 'none',
				borderTop: '1px solid var(--color-grey60)',
			},
		},
	},
}

export default CssBaseline
